<template>
  <div
    v-if="notification && notification.show"
    class="flex justify-between items-center w-full px-4 py-2 z-50 text-white text-lg rounded mt-2"
    :class="notification.status && notification.status.style"
  >
    <div class="flex justify-start">
      <p class="mr-8 text-sm font-semibold">{{ notification.text }}</p>
      <button
        v-if="notification.status && notification.status.btn"
        class="rounded px-2 py-1 text-xs bg-white text-red-500"
        @click.prevent="onClick"
      >
        {{ notification.status.btn.text }}
      </button>
    </div>
    <a href="#" @click.prevent="updateNotification({ ...notification, show: false })">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </a>
    <div class="opacity-0 absolute z-0 bg-yellow-500"></div>
  </div>
</template>

<script>
import { xhr } from '@/utils/axios'

export default {
  name: 'notification',
  methods: {
    onClick() {
      xhr.post('/client/stripe/customer_portal/').then(response => {
        window.location.href = response.data.customer_portal
      })
    },
  },
}
</script>

<style></style>
